<template>
    <b-form-checkbox-group
        @input="setFleetTags"
        v-model="tags"
        class="mt-2"
        :options="optionsListWithAcl"
    />
</template>

<script>
export default {
    name: 'FleetTagsGroupAcl',
    components: {
    },
    props: {
        model: {
            type: Array,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        aclField: {
            type: String,
            required: false,
            default: 'ACL:FLEET:EDIT_TAG',
        },
    },
    data() {
        return {
            tags: [],
            groups: [],
        };
    },
    computed: {
        optionsListWithAcl: {
            get() {
                return this.options.map(option => {
                    option.disabled = !this.checkAcl(option);
                    return option;
                });
            },
            set(value) {
                this.options = value;
            },
        },
    },
    mounted() {
        this.groups = this.$store.getters['user/groups'];
        this.tags = this.model

    },
    methods: {
        setFleetTags( values ) {
            this.$emit('setFleetTags', values);
        },

        checkAcl( option ) {
            if( !option.needAcl ) {
                return true;
            }

            if( this.groups.includes( `${this.aclField}:${option.value}`  ) ) {
                return true;
            }

            return false;
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
