<script>
/* eslint-disable vue/prop-name-casing */
import { enumStatus, enumOBDProvider, enumFleetFlags } from '@utils/car';

import CAR_CREATE from '@graphql/car/mutations/create.gql';
import CAR_PLACES from '@graphql/place/queries/car.gql';
import carCategory from '../select/car-category.vue';
import FleetTagsGroupAcl from '@components/fleet/fleet-tags-group-acl.vue';

export default {
  components: { carCategory, FleetTagsGroupAcl },
  name: 'ModalCarAdd',
  props: {
    orderId: {
      type: String,
      required: false,
      default: null,
    },
    placeId: {
      type: String,
      required: false,
      default: null,
    },
    product: {
      type: String,
      required: false,
      default: "RENTAL",
    },
  },
  data() {
    return {
      data: {
        clicked: false,
        license_plates: '',
        place: null,
        obd_provider: [],
        mobilized_at: null,
        billed_at: null,
        status: 'PRE_OPERATION',
        category: null,
      },
      send_button_clicked: false,
      form_status_options: enumStatus,
      form_obd_provider_options: [...enumOBDProvider, ...enumFleetFlags],
      block_ui: false,
    };
  },
  apollo: {
    places: {
      query: CAR_PLACES,
      update: data => data.places,
      skip() {
        return this.product === 'INSURANCE';
      },
    },
  },
  computed: {
    region() {
      return this.$store.getters['user/country'];
    },
    disableButtonAddCar() {
      return (
        this.send_button_clicked ||
        this.processPlates(this.data.license_plates).invalid.length > 0 ||
        this.processPlates(this.data.license_plates).duplicated.length > 0 ||
        this.processPlates(this.data.license_plates).valid.length <= 0
      );
    },
  },
  methods: {
    setFleetTags( fleetTags ) {
      this.data.obd_provider = fleetTags;
    },
    handleOpen() {
      this.data.license_plates = '';
      this.data.place = null;
      this.data.obd_provider = [];
    },
    processPlates(plates) {
      plates = plates.replace(/(\r\n|\n|\r)/gm, ',');
      plates = plates.toUpperCase();
      plates = plates.replace(' ', '');
      plates = plates.split(',');

      let _plates = {
        regexBrazil: /^[a-zA-Z]{3}[0-9]{4}$/,
        regexMercosul: /^[a-zA-Z]{3}[0-9][a-zA-Z][0-9]{2}$/,
        validate(plate, plateIndex) {
          if (plate.length > 0) {
            const validateLengthAndRegex =
              plate.length === 7 &&
              (plate.match(this.regexBrazil) ||
                plate.match(this.regexMercosul));
            if (this.region === 'BR') {
              if (!validateLengthAndRegex) {
                this.invalid.push(plate);
              } else if (plates.indexOf(plate) === plateIndex) {
                this.valid.push(plate);
              } else {
                this.duplicated.push(plate);
              }
            } else {
              this.valid.push(plate);
            }
          }
        },
        valid: [],
        invalid: [],
        duplicated: [],
      };
      plates.forEach((plate, index) => {
        _plates.validate(plate, index);
      });
      return _plates;
    },
    async doCreateCar() {
      const original_data = this.data;
      this.block_ui = true;
      this.$apollo
        .mutate({
          mutation: CAR_CREATE,
          variables: {
            order: this.orderId,
            place: this.data.place,
            license_plates: this.processPlates(this.data.license_plates).valid,
            product: this.product,
            obd_provider: this.data.obd_provider.join(';'),
            mobilized_at: this.data.mobilized_at,
            billed_at: this.data.billed_at,
            status: 'PRE_OPERATION',
            category: this.data.category
          },
        })
        .then(result => {
          if (result.data.createCar) {
            this.$root.$emit('bv::hide::modal', 'modalCar');
            this.$swal({
              type: 'success',
              title: 'Veículo criado com sucesso!',
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              this.$emit('carAdded');
            });
          } else {
            this.send_button_clicked = false;
            this.$swal({
              type: 'error',
              title: 'Ocorreu um erro!',
              text: 'Verifique a Placa, Renavam e Chassi (VIN) do carro',
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              this.block_ui = false;
            });
          }
        })
        .catch(error => {
          this.send_button_clicked = false;
          this.$swal({
            type: 'error',
            title:
              error.message.split('GraphQL error:')[1] || 'Ocorreu um erro!',
            text: 'Verifique os campos digitados novamente',
            showConfirmButton: false,
            timer: 2500,
          });
          this.data = original_data;
          this.block_ui = false;
        });
    },
    async handleCarSubmit() {
      this.send_button_clicked = true;
      this.doCreateCar();
    },
    changeCarCategory (value) {
      this.data.category = value
    }
  },
};
</script>

<template>
  <!-- Add Car Modal -->
  <b-modal
    id="modalCar"
    ref="modalCarAdd"
    lazy
    no-fade
    size="lg"
    title="Adicionar veículos"
    ok-title="Adicionar veículos"
    cancel-title="Cancelar"
    @show="handleOpen"
    @ok="handleCarSubmit"
  >
    <b-form v-if="!$apollo.queries.places.loading" name="car">
      <b-form-row>
        <b-form-group label="Placas" label-for="fleet_license_plates" class="col-md-12 col-sm-12">
          <b-form-textarea
            id="fleet_license_plates"
            v-model.trim="data.license_plates"
            :disabled="block_ui"
            :rows="3"
            class="plates"
            placeholder="Lista de placas separadas nova linha ou virgula"
          />
          <div
            v-if="this.processPlates(data.license_plates).invalid.length > 0"
            class="plates_invalid"
          >
            Placas inválidas:
            <strong>{{ this.processPlates(data.license_plates).invalid.join(', ') }}</strong>
          </div>
          <div
            v-if="this.processPlates(data.license_plates).duplicated.length > 0"
            class="plates_invalid"
          >
            Placas duplicadas:
            <strong>{{ this.processPlates(data.license_plates).duplicated.join(', ') }}</strong>
          </div>
        </b-form-group>
      </b-form-row>
      <span v-if="this.product === 'RENTAL'">
        <b-form-row>
          <b-form-group label="Pátio" label-for="fleet-place" class="col-md-6 col-sm-12">
            <b-form-select
              id="fleet-place"
              v-model="data.place"
              :disabled="block_ui"
              :options="places.items"
              value-field="id"
              text-field="name"
            />
          </b-form-group>
          <b-form-group
            label="Data mobilização"
            label-for="fleet-mobilized-at"
            class="col-md-3 col-sm-12"
          >
            <b-form-input
              id="leet-mobilized-at"
              v-model.trim="data.mobilized_at"
              :disabled="this.block_ui"
              type="date"
            />
          </b-form-group>
          <b-form-group
            label="Data início pagamento"
            label-for="fleet-billed-at"
            class="col-md-3 col-sm-12"
          >
            <b-form-input
              id="fleet-billed-at"
              v-model.trim="data.billed_at"
              :disabled="this.block_ui"
              type="date"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
           <car-category
            categoryId="fleet-category"
            :value="data.category"
            :disabled="this.block_ui"
            @category-value="changeCarCategory"
          />

          <b-form-group
            :label="$t('fleet.texts.fleettags')"
            label-for="fleet-obd-provider"
            class="col-md-12 col-sm-12"
          >
            <fleet-tags-group-acl
              :model="data.obd_provider"
              :options="form_obd_provider_options"
              @setFleetTags="setFleetTags"
            />
          </b-form-group>
        </b-form-row>
      </span>
    </b-form>
    <div slot="modal-footer">
      <b-button class="mr-3" @click.prevent="$root.$emit('bv::hide::modal', 'modalCar');">
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        :disabled="disableButtonAddCar"
        @click.prevent="handleCarSubmit"
      >
        Adicionar veículos
      </b-button>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.plates {
  text-transform: uppercase;
}
.plates_invalid {
  color: #f86c6b;
  padding-top: 2px;
  padding-left: 2px;
  font-size: 11px;
}
</style>
