<template>
  <div class="fleet-list-table">
    <custom-data-table
      ref="insuranceDataTable"
      name="insuranceDataTable"
      :fields="fields"
      :filters="filters"
      :query="car_list_query"
      :fixed-filters="{ status: ['ACTIVE', 'PRE_OPERATION'], product: 'INSURANCE' }"
      :sort="sort"
      query-node="carslist"
      @vuetable:row-clicked="$_goTo"
    >
      <span
        slot="license_plate"
        slot-scope="props"
      >
        <strong>{{ props.rowData.license_plate }}</strong>
        <br>
        <span class="small text-muted">
          <strong v-if="props.rowData.brand && props.rowData.model">
            {{ props.rowData.brand.name }} / {{ props.rowData.model.name }}
          </strong>
          <strong v-else>
            Processing...
          </strong>
        </span>
      </span>
      <span slot="created_at" slot-scope="props">{{ props.rowData.created_at | moment('DD/MM/YYYY HH:mm') }}</span>
      <span slot="status_operation" slot-scope="props">
        <b-badge :variant="props.rowData.status_operation">
          {{ props.rowData.status_operation ? props.rowData.status_operation : '--' }}
        </b-badge>
      </span>
      <span slot="edit" slot-scope="props">
        <b-button title="Editar carro" variant="link" @click.stop="$_goToCarEdit(props.rowData.id)">
          <i class="fa fa-edit" />
        </b-button>
      </span>
    </custom-data-table>
  </div>
</template>

<script>
import CustomDataTable from '@components/CustomDataTable';
import { enumStatusOperation } from '@utils/car';
import CAR_LIST from '@graphql/insurance/queries/list.gql';

export default {
  name: "InsuranceListTable",
  components: {
    CustomDataTable,
  },
  data() {
    return {
      car_list_query: CAR_LIST,
      sort: [['created_at', 'DESC']],
      fields: [
        {
          name: 'license_plate',
          title: 'Placa',
          sortField: 'license_plate',
          width: '60%',
        },
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Data criação',
          width: '20%',
        },
        {
          name: 'status',
          sortField: 'status',
          title: 'Status',
          width: '10%',
        },
        {
          name: 'status_operation',
          title: '',
          width: '9%',
        },
        {
          name: 'edit',
          title: '',
          width: '1%',
        }
      ],
    };
  },
  computed: {
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: 'Buscar por placa',
            placeholder: 'Ex: MGO3646',
          },
          graphqlQuery(value) {
            return {
              $or: [
                {
                  license_plate: {
                    $like: `%${value}%`,
                  },
                },
              ],
            };
          }
        },
        status_operation: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Status',
            options: [
              {
                value: null,
                text: 'Todos',
              },
              ...enumStatusOperation,
            ],
          },
          graphqlQuery(value) {
            return !value
              ? {
                  status_operation: enumStatusOperation.map(item => item.value),
                }
              : { status_operation: [value] };
          },
        }
      };
    },
  },
  methods: {
    $_goTo(_item = null) {
      _item = _item.data;
      this.$router.push(`/fleet/${_item.id}?redir=/insurance/fleet`);
    },
    $_goToCarEdit(id) {
      this.$router.push(`/fleet/${id}/edit`);
    }
  },
};
</script>

<style lang="sass" scoped>
.vuetable-th-slot-license_plate
  width: 250px
.vuetable-th-slot-edit
  width: 30px
.vuetable-th-slot-status_operation
  width: 120px
.edit-button
  padding: 0
  margin: 0
.badge
  padding: 5px 10px
  color: white
  &-LOCKED
    background: red
  &-LOCK_PENDING,
  &-UNLOCK_PENDING
    background: orange
  &-UNLOCKED
    background: green
</style>
