var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fleet-list-table" },
    [
      _c("custom-data-table", {
        ref: "insuranceDataTable",
        attrs: {
          name: "insuranceDataTable",
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.car_list_query,
          "fixed-filters": {
            status: ["ACTIVE", "PRE_OPERATION"],
            product: "INSURANCE",
          },
          sort: _vm.sort,
          "query-node": "carslist",
        },
        on: { "vuetable:row-clicked": _vm.$_goTo },
        scopedSlots: _vm._u([
          {
            key: "license_plate",
            fn: function (props) {
              return _c("span", {}, [
                _c("strong", [_vm._v(_vm._s(props.rowData.license_plate))]),
                _c("br"),
                _c("span", { staticClass: "small text-muted" }, [
                  props.rowData.brand && props.rowData.model
                    ? _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(props.rowData.brand.name) +
                            " / " +
                            _vm._s(props.rowData.model.name) +
                            " "
                        ),
                      ])
                    : _c("strong", [_vm._v(" Processing... ")]),
                ]),
              ])
            },
          },
          {
            key: "created_at",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(
                      props.rowData.created_at,
                      "DD/MM/YYYY HH:mm"
                    )
                  )
                ),
              ])
            },
          },
          {
            key: "status_operation",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-badge",
                    { attrs: { variant: props.rowData.status_operation } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            props.rowData.status_operation
                              ? props.rowData.status_operation
                              : "--"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "edit",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-button",
                    {
                      attrs: { title: "Editar carro", variant: "link" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$_goToCarEdit(props.rowData.id)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }