var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticClass: "col-md-3 col-sm-12",
      attrs: { label: "Categoria", "label-for": _vm.categoryId },
    },
    [
      _c("b-form-select", {
        attrs: {
          id: _vm.categoryId,
          disabled: _vm.disabled,
          options: _vm.carsCategory,
          "value-field": "value",
          "text-field": "text",
        },
        on: {
          change: function ($event) {
            return _vm.$emit("category-value", $event)
          },
        },
        model: {
          value: _vm.category,
          callback: function ($$v) {
            _vm.category = $$v
          },
          expression: "category",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }