var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalCarAdd",
      attrs: {
        id: "modalCar",
        lazy: "",
        "no-fade": "",
        size: "lg",
        title: "Adicionar veículos",
        "ok-title": "Adicionar veículos",
        "cancel-title": "Cancelar",
      },
      on: { show: _vm.handleOpen, ok: _vm.handleCarSubmit },
    },
    [
      !_vm.$apollo.queries.places.loading
        ? _c(
            "b-form",
            { attrs: { name: "car" } },
            [
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-12 col-sm-12",
                      attrs: {
                        label: "Placas",
                        "label-for": "fleet_license_plates",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        staticClass: "plates",
                        attrs: {
                          id: "fleet_license_plates",
                          disabled: _vm.block_ui,
                          rows: 3,
                          placeholder:
                            "Lista de placas separadas nova linha ou virgula",
                        },
                        model: {
                          value: _vm.data.license_plates,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data,
                              "license_plates",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "data.license_plates",
                        },
                      }),
                      this.processPlates(_vm.data.license_plates).invalid
                        .length > 0
                        ? _c("div", { staticClass: "plates_invalid" }, [
                            _vm._v(" Placas inválidas: "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  this.processPlates(
                                    _vm.data.license_plates
                                  ).invalid.join(", ")
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      this.processPlates(_vm.data.license_plates).duplicated
                        .length > 0
                        ? _c("div", { staticClass: "plates_invalid" }, [
                            _vm._v(" Placas duplicadas: "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  this.processPlates(
                                    _vm.data.license_plates
                                  ).duplicated.join(", ")
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              this.product === "RENTAL"
                ? _c(
                    "span",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-6 col-sm-12",
                              attrs: {
                                label: "Pátio",
                                "label-for": "fleet-place",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "fleet-place",
                                  disabled: _vm.block_ui,
                                  options: _vm.places.items,
                                  "value-field": "id",
                                  "text-field": "name",
                                },
                                model: {
                                  value: _vm.data.place,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "place", $$v)
                                  },
                                  expression: "data.place",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                label: "Data mobilização",
                                "label-for": "fleet-mobilized-at",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "leet-mobilized-at",
                                  disabled: this.block_ui,
                                  type: "date",
                                },
                                model: {
                                  value: _vm.data.mobilized_at,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "mobilized_at",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "data.mobilized_at",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                label: "Data início pagamento",
                                "label-for": "fleet-billed-at",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "fleet-billed-at",
                                  disabled: this.block_ui,
                                  type: "date",
                                },
                                model: {
                                  value: _vm.data.billed_at,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "billed_at",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "data.billed_at",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        [
                          _c("car-category", {
                            attrs: {
                              categoryId: "fleet-category",
                              value: _vm.data.category,
                              disabled: this.block_ui,
                            },
                            on: { "category-value": _vm.changeCarCategory },
                          }),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-12 col-sm-12",
                              attrs: {
                                label: _vm.$t("fleet.texts.fleettags"),
                                "label-for": "fleet-obd-provider",
                              },
                            },
                            [
                              _c("fleet-tags-group-acl", {
                                attrs: {
                                  model: _vm.data.obd_provider,
                                  options: _vm.form_obd_provider_options,
                                },
                                on: { setFleetTags: _vm.setFleetTags },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-3",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$root.$emit("bv::hide::modal", "modalCar")
                },
              },
            },
            [_vm._v(" Cancelar ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "primary", disabled: _vm.disableButtonAddCar },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleCarSubmit.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Adicionar veículos ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }