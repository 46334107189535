var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [_vm._v(" Seguros ")]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "6" } },
            [
              _c("b-button", { on: { click: _vm.addNewCar } }, [
                _vm._v(" " + _vm._s(_vm.$t("orders.texts.addNewCar")) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("fleet-list-table", { ref: "insuranceListTable" }),
      _c("modal-car-add", {
        ref: "ModalCarAdd",
        attrs: { product: "INSURANCE" },
        on: { carAdded: _vm.handlerCarAdded },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }