var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-checkbox-group", {
    staticClass: "mt-2",
    attrs: { options: _vm.optionsListWithAcl },
    on: { input: _vm.setFleetTags },
    model: {
      value: _vm.tags,
      callback: function ($$v) {
        _vm.tags = $$v
      },
      expression: "tags",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }