<template>
  <b-form-group
    label="Categoria"
    :label-for="categoryId"
    class="col-md-3 col-sm-12"
  >
    <b-form-select
      :id="categoryId"
      v-model="category"
      :disabled="disabled"
      :options="carsCategory"
      value-field="value"
      text-field="text"
      @change="$emit('category-value', $event)"
    />
  </b-form-group>
</template>
<script>
import { enumCarsCategory } from '@utils/car';

export default {
  name: 'CarCategorySelect',
  created() {
    this.category = this.value
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    categoryId: {
      type: String,
      required: true,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    category: ''
  }),
  computed: {
    carsCategory () {
      return [
        { value: '', text: 'Nenhuma' },
        ...enumCarsCategory
      ]
    }
  },
};
</script>
