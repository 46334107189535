<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>
          Seguros
        </h2>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button @click="addNewCar">
          {{ $t('orders.texts.addNewCar') }}
        </b-button>
      </b-col>
    </b-row>
    <fleet-list-table ref="insuranceListTable" />
    <modal-car-add
      ref="ModalCarAdd"
      product="INSURANCE"
      @carAdded="handlerCarAdded"
    />
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import FleetListTable from '@components/insurance/fleet-list-table';
import ModalCarAdd from '@components/modals/car-add';
import { isBrazil } from '@utils/helper-region';

export default {
  name: 'InsuranceFleet',
  page: {
    title: 'Seguros',
  },
  components: {
    Layout,
    FleetListTable,
    ModalCarAdd,
  },
  computed: {
    isBrazil,
  },
  methods: {
    addNewCar() {
      this.$refs.ModalCarAdd.$refs.modalCarAdd.show();
    },
    handlerCarAdded() {
      this.$refs.insuranceListTable.$refs.insuranceDataTable.$_refresh();
    },
  }
};
</script>
